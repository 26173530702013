@import '@royalaholddelhaize/design-system-pantry-web/utils/mq';

.root {
    padding: var(--sizing-core-12);
    background-color: var(--color-background-secondary-default);
}

.quicklinks {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.sitemap {
    padding: 0 var(--sizing-core-10) var(--sizing-core-12) var(--sizing-core-10);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: var(--sizing-core-12);
    border-bottom: 6px dashed var(--color-action-primary-disabled);

    .projects {
        flex-basis: 50%;
    }
}

.contactButton {
    display: flex;
    justify-content: end;
}

@include mq($until: medium) {
    .sitemap {
        flex-wrap: wrap;
        padding: 0 0 var(--sizing-core-8) 0;
    }

    .about {
        flex-basis: 50%;
    }

    .contact {
        margin-top: var(--sizing-core-8);
        flex-basis: 100%;
    }

    .contactButton {
        display: block;
    }
}

@include mq($until: small) {
    .root {
        padding: var(--sizing-core-4);
    }
}

.column {
    & > *:not(:first-child) {
        margin-top: var(--sizing-core-2);
    }
}

.links {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.logo {
    display: flex;
    flex-direction: row;
    align-items: center;
}